export const tabletsWidth = 640;
export const desktopWidth = 980;
export const largeDesktopWidth = 1280;

export const vimeoId = 663194030;
export const appStoreUrl = 'https://apps.apple.com/us/app/gritx/id1516634763?uo=4';
export const intIosAccess = 'https://testflight.apple.com/join/S2ng3lMy';
export const googlePlayUrl = 'https://play.google.com/store/apps/details?id=com.verbalize.gritx';
export const twitterUrl = 'https://twitter.com/GritStories/';
export const facebookUrl = 'https://www.facebook.com/gritstories/';
export const instagramUrl = 'https://www.instagram.com/gritstories/';
export const maxArticleCards = 12;
export const proUser = 'GritX Pro users';
export const maxEmails = 40;
export const mainVideoUrl = 'https://verbalize-staticcontent.azureedge.net/media/GritX%20for%20Students.mp4';
export const mainVideoPosterUrl = 'https://verbalize-staticcontent.azureedge.net/media/Gritx_poster.png';
export const doiUrl = 'https://doi.org/';

export const feedbackTypes = {
  issue: 'issue',
  newContent: 'newContent',
  joinUCSF: 'joinUCSF',
  shareHistory: 'shareHistory',
  chat: 'chat',
  newsletter: 'newsletter'
};

export const emailRegExp = new RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/);
export const fileSize = 10485760;

export enum ContentType {
  Meditation = 'MEDITATION',
  Skill = 'SKILL',
  SelfCare = 'Self-care',
  Journal = 'JOURNAL',
  SelfInterview = 'SELF_INTERVIEW'
}

export enum SystemLanguage {
  English = 'en',
  Spanish = 'es',
  Chinese = 'zh',
  French = 'fr',
  German = 'de',
  Hindi = 'hi',
  Malayalam = 'ml',
  Portuguese = 'pt',
  Filipino = 'fil',
  Tamil = 'ta',
  Vietnamese = 'vi'
}

export const languages = [
  {
    value: SystemLanguage.English,
    title: 'English',
    key: 'gritx.common.language.en'
  },
  {
    value: SystemLanguage.Spanish,
    title: 'Spanish',
    key: 'gritx.common.language.es'
  },
  {
    value: SystemLanguage.Chinese,
    title: 'Chinese',
    key: 'gritx.common.language.zh'
  },
  {
    value: SystemLanguage.French,
    title: 'French',
    key: 'gritx.common.language.fr'
  },
  {
    value: SystemLanguage.German,
    title: 'German',
    key: 'gritx.common.language.de'
  },
  {
    value: SystemLanguage.Hindi,
    title: 'Hindi',
    key: 'gritx.common.language.hi'
  },
  {
    value: SystemLanguage.Malayalam,
    title: 'Malayalam',
    key: 'gritx.common.language.ml'
  },
  {
    value: SystemLanguage.Portuguese,
    title: 'Portuguese',
    key: 'gritx.common.language.pt'
  },
  {
    value: SystemLanguage.Filipino,
    title: 'Filipino',
    key: 'gritx.common.language.fil'
  },
  {
    value: SystemLanguage.Tamil,
    title: 'Tamil',
    key: 'gritx.common.language.ta'
  },
  {
    value: SystemLanguage.Vietnamese,
    title: 'Vietnamese',
    key: 'gritx.common.language.vi'
  }
];

